<template scoped>
  <div class="designtool-start">
    <div>
      <el-card
        class="box-card"
        :style="{ background: '#EF8354', color: '#fff' }"
      >
        <span>Start</span>
      </el-card>
    </div>
    <DesignToolLogic-Arrow-Bottom> </DesignToolLogic-Arrow-Bottom>
  </div>
</template>

<script scoped>
export default {
  components: {
    "DesignToolLogic-Arrow-Bottom": () =>
      import(
        "./Arrow-Bottom.vue"
      ),
  },
};
</script>

<style scoped>


.designtool\-start {
  width: 300px;
  text-align: center;
}
</style>

